import React, { useState, useEffect, Fragment, useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BackArrow from "../../components/Navbar/BackArrow";
import { ModeSelectDropdown } from "../../components/Navbar/ModeSelect";
import NotificationIndicator from "../../components/Navbar/NotificationIndicator";
import LogoutButton from "../../components/Navbar/LogoutButton";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Link, useLocation } from "react-router-dom";
import RedeemIcon from "@material-ui/icons/Redeem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import HelpIcon from "@material-ui/icons/Help";
import { Slide, useScrollTrigger } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import { authContext } from "../../ProvideAuth";
import Modal from "components/Modal";
import SubscriptionExpire from "components/SubscriptionExpire";
import PortalStatus from "components/PortalStatus";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  CHIKITSA_SATHI_GUIDELINE_URL,
  DEFAULT_GUIDELINE_URL,
} from "../../utils/guidelinesUrl";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  list: {
    width: drawerWidth,
  },
  title: {
    padding: theme.spacing(0, 0, 0, 5),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  bottomRoot: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
  },
  subRoutes: {
    padding: theme.spacing(0, 0, 0, 8),
  },
  drawerPaperLiveMode: {
    backgroundColor: "rgb(61, 170, 153)",
    overflowY: "hidden",
  },
  drawerPaperPracticeMode: {
    overflowY: "hidden",
    background: "linear-gradient(60deg, rgb(232, 146, 40), rgb(251, 226, 194))",
  },
  subscription: {
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20%",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "15%",
    },
  },
  guidelines: {
    fontSize: 16,
    // fontWeight:600,
    fontStyle: "italic",
    letterSpacing: "0.035rem",
    textDecoration: "underline",
  },
}));

function NavBar(props) {
  const classes = useStyles();
  const [bottomValue, setBottomValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [showSubList, setShowSubList] = React.useState(false);
  const trigger = useScrollTrigger();
  const location = useLocation();
  const {
    skipSubscriptionWarning,
    isShowSubscriptionWarning,
    portalStatus,
  } = useContext(authContext);
  const subscriptionStatus = localStorage.getItem("ECONSULT_STORE_ST");

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen((prevState) => !prevState);
    return;
  };

  const toggleSubList = () => {
    setShowSubList((prevShowSubList) => !prevShowSubList);
  };

  const [username, setUserName] = useState(localStorage.getItem("username"));

  const [modeOfMso, setMode] = useState(
    JSON.parse(localStorage.getItem("mode") || 1)
  );
  const [modeTextColor, setModeTextColor] = useState(null);

  useEffect(
    () => setModeTextColor(modeOfMso ? { color: "black" } : { color: "white" }),
    [modeOfMso]
  );
  const updateMode = (value) => {
    console.log("updateMode is being run");
    setMode(value);
    setUserName(localStorage.getItem("username"));
  };
  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        msoMode: modeOfMso,
        onModeChange: (value) => updateMode(value),
      });
    }
    return child;
  });

  const msoPlanTitle = localStorage.getItem("ECONSULT_STORE_PLAN_TITLE");

  const guidlineUrl =
    msoPlanTitle === "chikitsa_sathi_5k"
      ? CHIKITSA_SATHI_GUIDELINE_URL
      : DEFAULT_GUIDELINE_URL;

  const primaryRoutes = [
    {
      path: "/",
      label: "Dashboard",
      icon: <DashboardIcon />,
    },
    {
      path: "/book_appointment?type=specific",
      label: "Doctors",
      icon: <LocalHospitalIcon />,
    },
    {
      path: "/account",
      label: "Account",
      icon: <AccountBoxIcon />,
    },
  ];

  const accountRoutes = [
    {
      path: "/account/profile",
      label: "Profile",
      icon: <AccountCircleIcon />,
    },
    {
      path: "/account/account_detail",
      label: "Account Details",
      icon: <AccountBalanceWalletIcon />,
    },
    {
      path: "/account/change_password",
      label: "Change Password",
      icon: <LockOpenIcon />,
    },
    {
      path: "/account/promocode",
      label: "Promocode",
      icon: <RedeemIcon />,
    },
    {
      path: "/account/camp",
      label: "Camp Details",
      icon: <LocalPharmacyIcon />,
    },
    {
      path: "/account/raise_issue",
      label: "Raise Issue",
      icon: <HelpIcon />,
    },
    {
      path: "/account/chat_support",
      label: "Chat Support",
      icon: <ChatIcon />,
    },
  ];

  function SideDrawer() {
    return (
      <Drawer
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: clsx(classes.drawerPaperLiveMode, {
            [classes.drawerPaperPracticeMode]: modeOfMso,
          }),
        }}
        onClose={toggleDrawer}
      >
        <div
          className={classes.list}
          role="presentation"
          onKeyDown={toggleDrawer}
        >
          <List>
            {primaryRoutes.map((route, index) => {
              return route.label !== "Account" ? (
                <ListItem
                  component={Link}
                  to={route.path}
                  key={index}
                  onClick={toggleDrawer}
                >
                  <ListItemIcon style={modeTextColor}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText
                    data-testid={`navigation-to-${route.label.toLowerCase()}-list`}
                    primary={route.label}
                  />
                </ListItem>
              ) : (
                <ListItem button key={index} onClick={toggleSubList}>
                  <ListItemIcon style={modeTextColor}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={route.label} />
                  {showSubList ? (
                    <ExpandLess data-testid={`expand-navigation-less-icon`} />
                  ) : (
                    <ExpandMore data-testid={`expand-navigation-more-icon`} />
                  )}
                </ListItem>
              );
            })}
            <Divider />
          </List>
          <Collapse in={showSubList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {accountRoutes.map((route, index) => (
                <ListItem
                  component={Link}
                  to={route.path}
                  key={index}
                  className={classes.nested}
                  onClick={toggleDrawer}
                >
                  <ListItemIcon style={modeTextColor}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText
                    data-testid={`navigation-to-${route.label.toLowerCase()}-list`}
                    primary={route.label}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
          <List>
            <ListItem>
              <ListItemIcon
                style={
                  modeOfMso === 0 ? { color: "#FFFFD4" } : { color: "black" }
                }
              >
                <AssignmentIcon />
              </ListItemIcon>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={guidlineUrl}
                  style={{ color: modeOfMso === 0 ? "#FFFFD4" : "black" }}
                  className={classes.guidelines}
                >
                  Guidelines for e-Clinic
                </a>
              </div>
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  }

  return (
    <Fragment>
      <div className={classes.grow}>
        {/* <BlockUi openBackdrop={openBackdrop} handleCloseBackdrop={handleCloseBackdrop}/> */}
        <AppBar
          position="sticky"
          style={
            modeOfMso
              ? {
                  background:
                    "linear-gradient(60deg, rgb(222, 135, 29), rgb(255, 232, 202))",
                }
              : { backgroundColor: "#3daa99" }
          }
        >
          <Toolbar>
            <Hidden mdDown>
              <IconButton
                data-testid={`toggle-drawer-icon`}
                onClick={toggleDrawer}
                edge="start"
              >
                <MenuIcon style={modeTextColor} />
              </IconButton>
              <SideDrawer />
            </Hidden>
            {location.pathname === "/vonage" ? null : (
              <>
                <BackArrow
                  data-testid={`back-icon`}
                  modeTextColor={modeTextColor}
                />
                <div>
                  <Typography
                    style={modeTextColor}
                    className={classes.title}
                    variant="h4"
                    noWrap
                  >
                    <Hidden smDown>Welcome </Hidden>
                    {username}
                  </Typography>
                  {subscriptionStatus === "expired" && (
                    <Typography
                      style={{ ...modeTextColor }}
                      className={classes.subscription}
                      variant="h4"
                    >
                      Subscription:{" "}
                      <span
                        style={{
                          fontWeight: 600,
                          color: modeOfMso ? "black" : "#ffdd45",
                        }}
                      >
                        Expired
                      </span>
                    </Typography>
                  )}
                </div>
                <div className={classes.grow} />
                <Hidden mdDown>
                  <ModeSelectDropdown
                    msoMode={modeOfMso}
                    onModeChange={(value) => updateMode(value)}
                    style={modeTextColor}
                  />
                </Hidden>
                <NotificationIndicator modeTextColor={modeTextColor} />
                <LogoutButton modeTextColor={modeTextColor} />
              </>
            )}
          </Toolbar>
        </AppBar>
        {childrenWithProps}
      </div>
      {location.pathname === "/vonage" ? null : (
        <Hidden lgUp>
          <Slide appear={false} direction="up" in={!trigger} timeout={500}>
            <BottomNavigation
              value={bottomValue}
              onChange={(event, newValue) => {
                setBottomValue(newValue);
              }}
              showLabels
              className={classes.bottomRoot}
              style={{ zIndex: 1000 }}
            >
              {primaryRoutes.map((route, index) => (
                <BottomNavigationAction
                  key={index}
                  component={Link}
                  to={route.path}
                  label={route.label}
                  icon={route.icon}
                />
              ))}
            </BottomNavigation>
          </Slide>
        </Hidden>
      )}
      {/* {isShowSubscriptionWarning.partial  && ( */}
      <Modal
        title=""
        width={600}
        open={isShowSubscriptionWarning.partial}
        onClose={skipSubscriptionWarning}
      >
        <SubscriptionExpire
          username={username}
          isShowSkipBtn={isShowSubscriptionWarning.partial}
          closeAlert={skipSubscriptionWarning}
        />
      </Modal>
      {/* )} */}
      {portalStatus === "2" && (
        <Modal
          title=""
          style={{ backgroundColor: "rgb(255 254 254)" }}
          width={500}
          open={portalStatus === "2"}
        >
          <PortalStatus />
        </Modal>
      )}
    </Fragment>
  );
}

export default NavBar;
