import React, { createContext, useEffect, useState } from "react";
import axios from "./utils/axios1";
import { set } from "lodash";

export const authContext = createContext(null);

const ECONSULT_STORE_PLAN_TITLE = "ECONSULT_STORE_PLAN_TITLE";

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState(localStorage.getItem("jwt") || "");
  const [isLoggedIn, setIsLoggedIn] = useState(
    // false,
    localStorage.getItem("jwt") ? true : false
  );
  const [isChecking, setIsChecking] = useState(false);
  const [isShowSubscriptionWarning, setIsShowSubscriptionWarning] = useState({
    partial: false,
  });
  const [portalStatus, setPortalStatus] = useState("1");

  useEffect(() => {
    if (isLoggedIn) {
      let portalStat = localStorage.getItem("ECONSULT_STORE_WEB_STAT");
      setIsChecking(true);
      let token = `Bearer ${jwt}`;
      axios
        .get("/is_valid_jwt", { headers: { Authorization: token } })
        .then((response) => {
          if (response?.status === 200) {
            setIsLoggedIn(true);
            // updateUser(jwt);
            console.log("About to set warning to true");
            console.log(
              "User is: ",
              user,
              "subscription is: ",
              user?.subscription,
              "plan is: ",
              user?.centre?.plan_title,
              "subscription status is: ",
              localStorage.getItem("ECONSULT_STORE_ST")
            );

            if (localStorage.getItem("ECONSULT_STORE_ST") === "expired") {
              // setIsShowSubscriptionWarning({ partial: true });
              updateUser(jwt);
            }
            // localStorage.setItem("ECONSULT_STORE_ST", subscriptionStatus);
            // const cannotSkip =
            //   user?.subscription?.subscription_status === "expired" &&
            //   [
            //     "100_free_clinic_plan",
            //     "1_month_free_trial",
            //     "1500_one_month_plan",
            //   ].includes(user?.centre?.plan_title);
            // console.log("cannotSkip is " + cannotSkip);
            // if (cannotSkip) {
            //   setIsShowSubscriptionWarning({
            //     partial: true,
            //   });
            //   console.log("Warning set to true after validating jwt");
            // }
          } else {
            signOut();
          }
          setIsChecking(false);
          setPortalStatus(portalStat);
        })
        .catch((error) => {
          console.error(error);
          setIsChecking(false);
          signOut();
        });
    }
  }, []);

  function skipSubscriptionWarning() {
    if (user) {
      const cannotSkip =
        user.subscription.subscription_status === "expired" &&
        [
          "100_free_clinic_plan",
          "1_month_free_trial",
          "1500_one_month_plan",
        ].includes(user.centre.plan_title);
      if (cannotSkip) {
        return;
      } else {
        setIsShowSubscriptionWarning((prevState) => ({
          ...prevState,
          partial: false,
        }));
      }
    }
    setIsShowSubscriptionWarning((prevState) => ({
      ...prevState,
      partial: false,
    }));
  }

  function signOut() {
    // the order here matters
    console.log("received request to sign out hence pages will change");
    const token = `Bearer ${jwt}`;
    if (user) {
      const id = user.id;
      axios.get(`/logout/${id}`, { headers: { Authorization: token } });
    }
    localStorage.clear();
    setUser(null);
    setIsLoggedIn(false);
  }

  async function signIn(responseJwt) {
    // the order here matters
    await updateUser(responseJwt);
    localStorage.setItem("jwt", responseJwt);
    console.log(`jwt received is ${responseJwt}`);
    setIsLoggedIn(true);
    setJwt(responseJwt);
  }

  async function updateUser(responseJwt = "") {
    console.log(`The jwt for getting the user is ${responseJwt}`);
    await axios
      .get("/current_user", {
        headers: { Authorization: `Bearer ${responseJwt}` },
      })
      .then((response) => {
        if (response.status === 200) {
          let userData = { ...response.data.data.attributes };
          setUser(userData);
          console.log("Current user received from the backend");
          console.log(userData);
          localStorage.setItem(
            "mode",
            response.data.data.attributes.is_practice_mode
          );
          localStorage.setItem("user", response.data.data.attributes.id);
          localStorage.setItem("state", response.data.data.attributes.state.id);
          localStorage.setItem("city", response.data.data.attributes.city.id);
          localStorage.setItem(
            "pincode",
            response.data.data.attributes.pincode
          );
          localStorage.setItem("username", response.data.data.attributes.name);
          localStorage.setItem(
            "centreTitle",
            response.data.data.attributes.centre?.centre_title
          );
          localStorage.setItem(
            "storeCode",
            response.data.data.attributes.centre?.store_code
          );
          localStorage.setItem("isCallExist", "0");
          localStorage.setItem(
            ECONSULT_STORE_PLAN_TITLE,
            response.data.data.attributes.centre?.plan_title
          );
          let subscriptionStatus =
            response.data.data.attributes?.subscription?.subscription_status;
          localStorage.setItem("ECONSULT_STORE_ST", subscriptionStatus);
          if (
            subscriptionStatus === "expiringSoon" ||
            subscriptionStatus === "expired"
          ) {
            setIsShowSubscriptionWarning((prevState) => ({
              ...prevState,
              partial: true,
            }));
          }
          localStorage.setItem(
            "ECONSULT_STORE_WEB_STAT",
            response.data.data.attributes.status?.key
          );
          setPortalStatus(response.data.data.attributes.status?.key.toString());
        }
        console.log("now the user Data should have been set");
      })
      .catch((response, error) => {
        console.log("error " + error);
        console.log(response);
      });
  }

  // might require this function to return something
  // async function checkAuthenticity(givenJwt = localStorage.getItem("jwt")) {
  //   if (givenJwt) {
  //     console.log("Checking vaidity of JWT");
  //     console.log(`Token being sent is ${givenJwt}`);
  //     console.log(`whereas the react state is holding the token as ${jwt}`);
  //     let token = `Bearer ${givenJwt}`;
  //     await axios
  //       .get("/is_valid_jwt", { headers: { Authorization: token } })
  //       .then(response => {
  //         console.log("response received for token check");
  //         console.log(response);
  //         if (response.status === 200) {
  //           setIsLoggedIn(true);
  //         } else {
  //           signOut();
  //         }
  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });
  //   }
  // }

  return {
    user,
    isLoggedIn,
    jwt,
    signIn,
    signOut,
    isChecking,
    skipSubscriptionWarning,
    isShowSubscriptionWarning,
    portalStatus,
  };
}

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
