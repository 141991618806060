import React, { useEffect } from "react";
import { Grid, Checkbox, Select, MenuItem, Button } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";

const styles = (theme) => ({
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    fontSize: "14px",
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      top: 10,
      padding: "9px 25px",
    },
    [theme.breakpoints.down("sm")]: {
      top: 10,
      padding: "12px 9px",
      fontSize: 13,
    },
  },
  buttonGrid: {
    [theme.breakpoints.up("sm")]: {
      height: 0,
    },
  },
  formGroup: {
    padding: "10px 10px",
  },
});
const useStyles = makeStyles(styles);

function SpecialCampForm(props) {
  const {
    isFemale,
    setIsPregnant,
    submitHandler,
    setSpecialDoctorRequest,
  } = props;
  const classes = useStyles();
  const { control, watch } = useForm();

  const isPregnant = watch("isPregnant");
  const specialDoctorRequest = watch("specialDoctorRequest");

  useEffect(() => {
    setIsPregnant(isPregnant);
    setSpecialDoctorRequest(specialDoctorRequest);
  }, [
    isPregnant,
    specialDoctorRequest,
    setIsPregnant,
    setSpecialDoctorRequest,
  ]);

  return (
    <div>
      <Grid className={classes.formGroup} item xs={12} sm={12} md={12}>
        {isFemale && (
          <div>
            <label>Is Pregnant</label>
            <Controller
              control={control}
              as={<Checkbox />}
              name="isPregnant"
              defaultValue={false}
            />
          </div>
        )}
        <div>
          <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
            <label>Special doctor request</label>
            <Controller
              control={control}
              as={
                <Select>
                  <MenuItem key="default">Select one</MenuItem>
                  (isFemale &&
                  <MenuItem key="gynaecologist" value="gynaecologist">
                    Gynaecologist
                  </MenuItem>
                  )
                  <MenuItem key="neurologist" value="neurologist">
                    Neurologist
                  </MenuItem>
                  <MenuItem key="dermatologist" value="dermatologist">
                    Dermatologist
                  </MenuItem>
                  <MenuItem key="orthopaedic" value="orthopaedic">
                    Orthopaedic
                  </MenuItem>
                </Select>
              }
              name="specialDoctorRequest"
              defaultValue={""}
              style={{ minWidth: 64 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
              paddingBottom: 12,
            }}
          >
            <Button
              className={classes.button}
              style={{ backgroundColor: "#2979ff", textAlign: "center" }}
              onClick={submitHandler}
            >
              Book
            </Button>
          </div>
        </div>
      </Grid>
    </div>
  );
}

export default SpecialCampForm;
